.sub-secondary-menu {
  display: flex;
  .left-content {
    width: 100%;
    max-width: 472px;
    background-color: var(--white);
    box-shadow: 2px 7px 20px rgba(44, 52, 94, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 36px;
    .left-content-block {
      max-width: 320px;
      width: 100%;
      margin: auto;
      .left-content-header {
        text-align: center;
        margin-bottom: 36px;
        .title {
          font-size: 26px;
          color: #474657;
          font-weight: 500;
          line-height: 31px;
          letter-spacing: 1.25px;
        }
        .sub-title {
          margin-top: 11px;
          letter-spacing: 0.77px;
          color: #77808d;
          line-height: 20px;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .switch-with-label {
        justify-content: center;
        margin-bottom: 25px;
        .ant-form-item {
          margin-bottom: 0;
          width: auto;
        }
      }
      .coupon-widget-wrapper {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 2px 7px 40px rgba(83, 85, 128, 0.15);
        padding: 20px;
        margin-bottom: 25px;
        &.coupon-widget-wrapper-dark {
          background-color: #352a72;
          .text-with-edit-icon {
            .my-custom-textarea {
              textarea {
                color: #fff;
              }
            }
          }
        }
        .upload-image-preview {
          min-width: 40px;
          height: 40px;
          border-radius: 12px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .ant-upload-list {
          border: 1px dashed #93bdff;
          border-radius: 4px;
          background-color: rgba(147, 189, 255, 0.1);
          max-width: 204px;
          margin: 0 auto;
          .ant-upload {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 80px;
            margin: 0;
            border-radius: 4px;
            background-color: transparent;
            .file-name {
              height: 40px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .ant-upload {
              padding: 0;
            }
          }
        }
        .text-with-edit-icon {
          border: 1px dashed #93bdff;
          border-radius: 4px;
          position: relative;
          max-width: 270px;
          margin: 10px auto 0;
          .anticon {
            background-color: #408ff0;
            width: 15px;
            height: 15px;
            border-radius: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
            position: absolute;
            top: -5px;
            right: -8px;
            z-index: 1;
          }
          .my-custom-textarea {
            display: flex;
            textarea {
              border-style: none;
              box-shadow: none;
              text-align: center;
              z-index: 0;
              line-height: 30px;
              font-size: 22px;
              color: #474657;
              padding: 5px 2px;
              width: 100%;
              height: 100%;
              overflow-y: auto;
              background-color: transparent;
              font-weight: bold;
              &:focus {
                background-color: transparent !important;
              }
            }
          }
          + .text-with-edit-icon {
            .my-custom-textarea {
              textarea {
                font-size: 14px;
                padding: 7px;
                min-height: 54px;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }
        }
        .button-with-edit-icon {
          margin-top: 20px;
          position: relative;
          .swipe-to-start-button {
            width: 100%;
            height: 60px;
            position: relative;
            background-color: #5b66ea;
            border-radius: 100px;
            padding: 6px 6px;
            display: flex;
            align-items: center;
            .icon-wrapper {
              height: 48px;
              min-width: 48px;
              background-color: #fff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .ant-form-item {
              margin-bottom: 0;
            }
            .text-with-edit-icon {
              margin-top: 0;
              position: relative;
              padding: 0 20px;
              .my-custom-input {
                padding: 2px;
                background-color: transparent;
                border-radius: 4px;
                input {
                  text-align: center;
                  color: #fff;
                  line-height: 20px;
                  font-size: 16px;
                  background-color: transparent;
                  outline: none;
                  border-style: none;
                  font-weight: 600;
                  max-width: 135px;
                  &::placeholder {
                    color: #fff;
                  }
                }
              }
              .anticon {
                background-color: #fff;
                width: 15px;
                height: 15px;
                border-radius: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
                position: absolute;
                top: -5px;
                right: -8px;
                z-index: 1;
                svg {
                  margin-left: 1px;
                  path {
                    fill: #408ff0;
                  }
                }
              }
            }
          }
          .color-edit {
            cursor: pointer;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
            position: absolute;
            top: -2px;
            right: -4px;
            z-index: 1;
            background-color: #fff;
            border-radius: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 19px;
            height: 19px;
          }
        }
      }
      .ant-tabs {
        .ant-tabs-nav {
          margin-bottom: 0;
          width: 100%;
          position: relative;
          z-index: 2;
          &::before {
            display: none;
          }
          .ant-tabs-nav-operations {
            display: none;
          }
          .ant-tabs-nav-wrap {
            display: flex;
            justify-content: flex-end;
            &::before {
              display: none;
            }
          }
          .ant-tabs-nav-list {
            position: relative;
            &::before {
              content: '';
              height: 1px;
              position: absolute;
              bottom: 0;
              background-color: rgba(208, 209, 221, 0.34);
              width: 100%;
            }
            .ant-tabs-tab {
              padding: 0;
              .ant-tabs-tab-btn {
                font-size: 12px;
                padding: 0 16px 7px;
                font-weight: 500;
                line-height: 16px;
                color: #352a72;
              }
              + .ant-tabs-tab {
                margin-left: 0px;
              }
              &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                  color: #5b66ea;
                  font-weight: bold;
                }
              }
            }
            .ant-tabs-ink-bar {
              background-color: #5b66ea;
              border-radius: 100px 100px 0 0;
            }
          }
        }
        .ant-tabs-content-holder {
          position: relative;
          z-index: 1;
          top: -20px;
          .ant-form-item {
            margin-bottom: 0;
          }
        }
      }
      .create-coupon-textarea-with-clipboard {
        position: relative;
        width: 100%;
        .create-coupon-textarea-container {
          .create-coupon-textarea-autosize {
            width: 100%;
            height: 390px;
            max-height: 390px;
            font-size: 12px;
            padding: 20px;
            position: relative;
          }
          .copy-button {
            padding: 0;
            width: 60px;
            border-radius: 100px;
            font-size: 10px;
            position: absolute;
            bottom: 20px;
            right: 20px;
            z-index: 1;
          }
        }
      }
    }
  }
  .right-content {
    width: 100%;
    height: 100%;
    margin-left: 26px;
    background-color: var(--white);
    box-shadow: 2px 7px 20px rgba(44, 52, 94, 0.1);
    overflow: hidden;
    padding: 28px;
    border-radius: 8px;
    .matador-connect-preview-default {
      padding-left: 5%;
      padding-right: 5%;
    }
    .phone-icon-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

.location-coupon-wrapper {
  .mat-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
}

.edit-drop-wrapper {
  .ant-popover-content {
    .ant-popover-arrow {
      left: 3px;
    }
    .ant-popover-inner {
      margin-left: -7px;
      box-shadow: 0 2px 13px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      width: 137px;
      padding: 6px;
      .ant-popover-inner-content {
        padding: 0;
        .popup-picker-color {
          display: flex;
          align-items: center;
          position: relative;
          .ant-form-item {
            margin-bottom: 0;
            .ant-form-item-control-input {
              min-height: auto;
              .ant-form-item-control-input-content {
                display: flex;
                align-items: center;
              }
            }
            .ant-input-affix-wrapper {
              height: 100%;
              padding: 7px;
              font-size: 11px;
              .ant-input {
                font-size: 11px;
                padding-right: 25px;
              }
              .ant-input-suffix {
                display: none;
              }
            }
          }
          .picker-color {
            position: absolute;
            right: 7px;
            z-index: 2;
            .color-trigger {
              width: 17px;
              height: 17px;
              border-radius: 4px;
            }
          }
        }
        .font-size-wrapper {
          margin-top: 6px;
          .ant-form-item {
            margin-bottom: 0;
            .ant-input-number {
              padding: 7px;
              height: 33px;
              .ant-input-number-input-wrap {
                display: flex;
                height: 100%;
              }
              .ant-input-number-input {
                font-size: 11px;
                height: auto;
              }
            }
          }
        }
        .language-wrapper {
          margin-bottom: 6px;
          .ant-form-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.sub-secondary-menu
  .left-content
  .left-content-block
  .coupon-widget-wrapper.coupon-widget-wrapper-dark
  .file-upload
  .file-image
  .no-file,
.sub-secondary-menu
  .left-content
  .left-content-block
  .coupon-widget-wrapper.coupon-widget-wrapper-dark
  .file-upload
  .file-image
  .file-name {
  color: #fff;
}

.sub-secondary-menu
  .left-content
  .left-content-block
  .coupon-widget-wrapper
  .ant-upload-list
  .ant-upload
  .no-file,
.sub-secondary-menu
  .left-content
  .left-content-block
  .coupon-widget-wrapper
  .ant-upload-list
  .ant-upload
  .file-name {
  letter-spacing: 0.62px;
  color: #77808d;
  padding-right: 20px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 33px;
  padding: 0 7px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 11px;
  line-height: 33px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-arrow {
  right: 7px;
  font-size: 8px;
  top: 19px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-open.ant-select-single:not(.ant-select-customize-input)
  .ant-select-arrow {
  top: 15px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  ):not(.ant-select-customize-input)
  .ant-select-selection-search-input {
  height: 33px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  ):not(.ant-select-customize-input)
  .ant-select-selection-placeholder {
  padding-right: 0;
  line-height: 33px;
  font-size: 11px;
  color: #77808d;
}

@keyframes run {
  0% {
    left: 5px;
  }
  40% {
    left: 5px;
  }
  60% {
    left: 30px;
  }
  70% {
    left: 30px;
  }
  100% {
    left: 5px;
  }
}

@media screen and (max-width: 1290px) {
  .sub-secondary-menu {
    .right-content {
      .matador-connect-preview-default {
        align-items: flex-start;
        padding-top: 10%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .organizations-tabs-wrapper {
    .my-page-full {
      padding: 20px;
    }
  }

  .sub-secondary-menu {
    flex-direction: column;
  }

  .sub-secondary-menu {
    .left-content {
      width: 100%;
      max-width: 100%;
      overflow: visible;
    }
    .right-content {
      width: 100%;
      margin-top: 30px;
      margin-left: 0;
      .matador-connect-preview-default {
        align-items: center;
        padding-top: 0%;
      }
      .phone-icon-wrapper {
        .phone-icon {
          position: relative;
        }
      }
    }
  }

  .crm-type-field {
    .input-form-item-wrapper {
      width: 33.333%;
    }
  }
}

@media screen and (max-width: 565px) {
  .crm-type-field {
    .crm-type {
      width: 100% !important;
    }
    .input-form-item-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 479px) {
  .edit-drop-wrapper {
    .ant-popover-content {
      .ant-popover-arrow {
        left: auto;
        right: 3px;
      }
      .ant-popover-inner {
        margin-left: 0;
        margin-right: -7px;
      }
    }
  }

  .mat-form {
    .mat-block-with-label {
      &.connect-email-btn-wrapper {
        .row-div-container {
          flex-direction: column;
        }
      }
    }
  }
}
