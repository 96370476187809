.livechat-settings-wrapper {
  .form-chatboxPreview-wrapper {
    display: flex;
    width: 100%;
    .ant-form {
      &.mat-form {
        width: 100%;
        background-color: var(--white);
        box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
        border-radius: 8px;
        overflow: hidden;
        padding: 36px;
        .mat-tabs {
          border-bottom: 1px solid var(--borderLineColor);
          margin-bottom: 20px;
        }
        .location-settings-switchs-wrapper {
          display: flex;
          flex-wrap: wrap;
          .switch-row-secondary {
            width: auto;
            margin-right: 16px;
          }
        }
      }
    }
    .mat-block-with-label {
      border-bottom: 1px solid var(--borderLineColor);
      margin-bottom: 20px;
    }
  }
  .text-area-copy-wrapper {
    position: relative;
    margin-bottom: 25px;
    .copy-button {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 10;
    }
  }
}

.matador-connect-settings-chat-wrapper {
  width: 100%;
  height: 100%;
  margin-left: 26px;
  background-color: var(--white);
  box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
  overflow: hidden;
  padding: 28px;
  border-radius: 8px;
  .chatbox-preview-v2 {
    width: 100%;
    background-color: var(--lighter);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 36px;
  }
}

@media screen and (max-width: 1290px) {
  .livechat-settings-wrapper {
    .form-chatboxPreview-wrapper {
      flex-direction: column;
      .ant-form {
        &.mat-form {
          max-width: 100%;
          overflow: visible;
        }
      }
      > .chatbox-preview-light-theme-v2 {
        padding-bottom: 100px;
        margin: 0 auto;
      }
    }
  }

  .matador-connect-settings-chat-wrapper {
    margin-left: 0;
    margin-top: 32px;
    .chatbox-preview-v2 {
      padding: 36px 36px 100px 36px;
    }
  }
}

@media screen and (max-width: 767px) {
  .livechat-settings-wrapper {
    .form-chatboxPreview-wrapper {
      .ant-form {
        &.mat-form {
          max-width: none;
        }
      }
    }
  }

  .matador-connect-settings-chat-wrapper {
    margin-top: 20px;
    padding: 20px;
    .chatbox-preview-v2 {
      padding: 20px 20px 90px;
    }
    .form-chatboxPreview-wrapper {
      .ant-form {
        &.mat-form {
          padding: 20px;
        }
      }
    }
  }
}
