.location-review-invites-wrapper {
  .review-invites-title {
    font-size: 16px;
    font-weight: bold;
    color: var(--textColor);
  }
  .ant-form-item-has-success {
    .mat-input-affix-wrapper {
      border-color: var(--grey);
      &:focus {
        border-color: var(--mainColor);
      }
    }
  }
  .review-content-wrapper {
    display: flex;
  }
  .review-templates {
    width: 50%;
    background-color: var(--white);
    box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
    border-radius: 8px;
    padding: 24px;
    margin-right: 10px;
  }
  .review_invite_block {
    width: 49%;
    background-color: var(--white);
    box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 20px;
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--black);
    }
    .review_invite_rate {
      flex-direction: row-reverse;
      display: flex;
      justify-content: flex-end;
      margin-left: -10px;
      .review_invite_rate_item {
        height: 36px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--newGrey);
        align-items: center;
        justify-content: center;
        display: flex;
        color: var(--mainColor);
        font-size: 16px;
        font-weight: 700;
      }
      .ant-rate-star {
        margin-inline-end: 0px;
        width: 36px;
        margin-left: 10px;
        &.ant-rate-star-full {
          .review_invite_rate_item {
            border: 1px solid var(--mainColor);
            background-color: #eeeffc;
          }
        }
        &.ant-rate-star-zero {
          .ant-rate-star-second {
            color: var(--mainColor);
          }
        }
      }
    }
  }
  .mat-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
  .mat-color-trigger {
    &.none {
      background-color: transparent;
      border: none;
    }
  }
  .review-posters {
    width: 50%;
    background-color: var(--white);
    box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
    border-radius: 8px;
    padding: 24px;
    margin-left: 10px;
  }
  .posters-cards {
    display: flex;
    margin-top: 23px;
  }
}

.location-review-invites-layout {
  &.my-page {
    max-width: none;
  }
}

.posters-wrapper {
  display: flex;
}

.uploaded-poster {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mat-tabs {
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    width: 100%;
    display: flex;
    justify-content: center;
    .ant-tabs-tab-btn {
      font-size: 12px;
    }
  }
  .ant-tabs-ink-bar {
    background: var(--mainColor);
  }
  .ant-tabs-nav {
    .ant-tabs-nav-operations {
      display: none;
    }
  }
}

.mat-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn,
.mat-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--textColor);
}

.posters-cards {
  .poster-wrapper-lang {
    display: flex;
    flex-direction: column;
    width: 100%;
    &:last-child {
      margin-left: 10px;
    }
  }
  .ant-upload {
    width: 100% !important;
    height: 102px !important;
    background-color: var(--newLightGrey) !important;
  }
}

.drag-drop-item {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  background-color: var(--newLightGrey);
  padding: 12px 16px;
  border-radius: 9px;
  align-items: center;
  .modal-btn {
    display: flex;
    align-items: center;
  }
  &:hover {
    box-shadow: 0 1px 4px rgb(0 0 0 / 16%);
  }
  .mat-form-item {
    margin-bottom: 0;
    padding-right: 10px;
    flex: 1 1;
    .mat-input-affix-secondary-wrapper:not(.ant-input-number) {
      background-color: var(--white);
    }
  }
  .grab-logo {
    align-self: center;
    svg {
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 991px) {
  .location-review-invites-wrapper {
    .review-content-wrapper {
      flex-direction: column;
    }
    .review-templates {
      margin-right: 0;
      border-right: none;
      width: 100%;
    }
    .review_invite_block {
      margin-right: 0;
      border-right: none;
      width: 100%;
    }
    .review-posters {
      margin-left: 0;
      width: 100%;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .location-review-invites-wrapper {
    .review-posters {
      margin-top: 20px;
    }
  }
}
