

.text-me-wrapper {
  .my-page {
    min-width: 1090px;
  }
  .button-style-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    .button-style-prop {
      max-width: 90px;
    }
    .position-item-style {
      &:nth-child(9) {
        grid-column: 1 / span 2;
        max-width: 209px;
      }
      :nth-child(10) {
        grid-column: 3 / span 2;
      }
    }
  }
  .color-pick-text-me {
    width: 100%;
    .mat-color-trigger {
      width: 50px;
      height: 50px;
    }
  }
  .checkbox-style-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-items: flex-start;
    align-items: center;
    .mat-checkbox-wrapper {
      display: flex;
      align-items: center;
      &:nth-child(4) {
        margin-bottom: 25px;
      }
    }
    .mat-checkbox-black {
      .ant-checkbox {
        + span {
          padding-top: 5px;
        }
      }
    }
    .checkbox-item {
      max-width: 122px;
    }
    .button-style-prop {
      max-width: 90px;
    }
  }
  .text-area-copy-wrapper {
    position: relative;
    margin-bottom: 25px;
    .copy-button {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 10;
    }
    .mat-label {
      display: flex;
      justify-content: space-between;
    }
    .second-label {
      font-size: 14px;
      font-weight: 700;
      color: var(--secondaryTextColor);
    }
  }
  .mat-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
  .form-button-wrapper {
    .ant-form {
      &.mat-form {
        width: 100%;
        background-color: var(--white);
        box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
        border-radius: 8px;
        overflow: hidden;
        padding: 36px;
      }
    }
  }
}

.form-button-wrapper {
  display: flex;
  .mat-form {
    width: 60%;
  }
  .button-phone-wrapper {
    width: 100%;
    height: 100%;
    margin-left: 26px;
    background-color: var(--white);
    box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
    overflow: hidden;
    padding: 28px;
    border-radius: 8px;
    .img-wrapper {
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.3);
      border-radius: 33px;
      width: 250px;
      height: 502px;
      position: relative;
      .btn-wrapper {
        position: relative;
        bottom: 176px;
        width: 100%;
        padding: 0 12px;
        display: flex;
        height: 63px;
        align-items: center;
        justify-content: center;
        button {
          padding: 0 10px;
          display: flex;
          justify-content: center;
          width: 100%;
          align-items: center;
          border-style: solid;
          font-weight: 700;
          &.anim {
            animation: nudge 3s ease-in-out infinite;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1190px) {
  .text-me-wrapper {
    .form-button-wrapper {
      .ant-form {
        &.mat-form {
          max-width: 100%;
          overflow: visible;
        }
      }
    }
    .button-phone-wrapper {
      margin-top: 30px;
      margin-left: 0;
      .img-wrapper {
        position: relative;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .text-me-wrapper {
    .form-button-wrapper {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 991px) {
  .text-me-wrapper {
    .button-phone-wrapper {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 575px) {
  .text-me-wrapper {
    .button-style-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
      .position-item-style {
        &:nth-child(9) {
          grid-column: 1 / span 2;
          max-width: 209px;
        }
        :nth-child(10) {
          grid-column: 1 / span 2;
          max-width: 209px;
        }
      }
    }
    .ant-form {
      .ant-form-item {
        flex-wrap: none;
      }
    }
    .ant-form-vertical {
      .ant-form-item {
        flex-direction: row;
      }
    }
    .checkbox-style-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 479px) {
  .text-me-wrapper {
    .button-style-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (min-width: 1200px) {
  .subscriber-growth-form {
    max-width: 45%;
  }
}
