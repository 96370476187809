.location-list-wrapper {
  padding: 0px 20px 20px 12px;
  height: auto;
  background-color: var(--newBackgroundGrey);
  padding-top: 10px;
  .location-card {
    padding: 25px 30px;
    background-color: var(--white);
    border-radius: 9px;
    h6 {
      font-size: 14px;
      font-weight: 700;
      color: var(--textColor);
      &:first-child {
        margin-bottom: 25px;
      }
    }
    .field-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &:first-child {
        border-style: none;
      }
      .field-label {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          fill: #cdcfe0;
        }
      }
      .show-more-action {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        p {
          font-weight: 700;
          color: var(--mainColor);
        }
        svg {
          margin-left: 5px;
        }
        .open {
          svg {
            transform: rotate(180deg);
          }
        }
      }
      .content-managers {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .content-address {
        display: inline-block;
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.open {
          white-space: normal;
        }
      }
    }
    .sms-images {
      display: grid;
      grid-template-columns: repeat(2, minmax(calc(50% - 10px), 1fr));
      grid-gap: 20px;
      margin-top: 20px;
      .ant-image-mask-info {
        display: flex;
        align-items: center;
        .anticon {
          margin-bottom: 2px;
        }
      }
      .ant-image-mask {
        border-radius: 8px;
      }
      .ant-image-img {
        height: 212px;
        width: 100%;
        background-color: var(--newLightGrey);
        object-fit: contain;
        border-radius: 8px;
      }
      .no-image {
        background-color: var(--newLightGrey);
        width: 100%;
        height: 212px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .item-urls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    a {
      + a {
        margin-left: 5px;
      }
    }
    .custom-url-icon {
      background: var(--newLightGrey);
      width: 21px;
      height: 21px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        width: auto !important;
        font-weight: 700;
        color: #fff;
      }
    }
  }
  .edit-location-button {
    width: 100%;
  }
}

.location-top-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .inputWithSearch {
    width: 400px;
  }
  .loc-header-select {
    width: 160px;
  }
  .mat-btn {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1190px) {
  .location-list-wrapper {
    .grid-container {
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    }
  }
}

@media only screen and (max-width: 1190px) {
  .location-list-wrapper {
    padding: 10px 20px 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .location-top-header {
    flex-direction: column;
    .inputWithSearch {
      width: 100%;
      margin: 10px 0px 10px 0px;
    }
    .d-flex {
      width: 100%;
      margin-right: 0;
    }
    .mat-btn {
      min-width: auto !important;
      width: 100%;
    }
    .loc-header-select {
      width: 100%;
    }
  }
}
