.my_default_select {
  height: 44px;
  min-width: 200px;
  .ant-select-selector {
    padding: 12px 12px 12px 16px !important;
    border-radius: 8px;
    border: 1px solid var(--newGrey) !important;
    background-color: var(--white) !important;
    height: 100%;
    display: flex;
    align-items: center;
    .ant-select-selection-item {
      color: var(--black);
      font-weight: 500;
      font-size: 16px;
      flex: 1;
      position: relative;
      user-select: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ant-select-selection-placeholder {
      font-size: 16px;
      color: var(--thridyTextColor);
      font-weight: 400;
      letter-spacing: 0;
    }
    .ant-select-selection-search {
      inset-inline-start: 16px;
      input {
        color: var(--black);
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }
  &.grey {
    .ant-select-selector {
      border: 1px solid var(--newGrey) !important;
      background-color: var(--newBackgroundGrey) !important;
    }
  }
  &.ant-select-multiple {
    height: auto;
    min-height: 44px;
    .ant-select-selector {
      padding: 0 !important;
      min-height: 44px;
      .ant-select-selection-search {
        inset-inline-start: 0px;
        margin-inline-start: 0;
      }
      .ant-select-selection-placeholder {
        inset-inline-start: 16px;
        position: absolute;
      }
      .ant-select-selection-overflow {
        gap: 6px;
        padding: 12px 34px 12px 16px !important;
        .ant-select-selection-item {
          border-radius: 100px;
          background-color: var(--mainColorLighter);
          color: var(--mainColor);
          font-size: 12px;
          font-weight: 600;
          display: flex;
          align-items: center;
          padding: 3px 14px;
          line-height: normal;
          line-height: 18px;
          height: auto;
          margin: 0;
          .ant-select-selection-item-content {
            white-space: normal;
          }
        }
        .ant-select-selection-item-remove {
          color: var(--thridyTextColor);
          font-size: 10px;
          font-weight: 600;
        }
      }
    }
  }
  .ant-select-clear {
    right: 40px;
  }
  .ant-select-arrow {
    inset-inline-end: 22px;
  }
  &.enable_margin {
    margin-bottom: 24px;
  }
  &.full_width {
    width: 100%;
  }
  &.ant-select-allow-clear {
    .ant-select-selector {
      .ant-select-selection-item {
        padding-inline-end: 38px;
      }
    }
  }
  &.ant-select-status-error {
    .ant-select-selector {
      background-color: var(--newBackgroundGrey) !important;
      box-shadow: none;
      border-color: #ff4d4f !important;
    }
  }
}

.my_default_select--dropdown {
  .ant-select-item {
    padding: 2px 0px;
    background-color: transparent !important;
    .ant-select-item-option-content {
      font-weight: 500;
      color: var(--textColor);
      font-size: 14px;
      padding: 8px;
      border-radius: 8px;
      width: 100%;
      flex: none;
      padding-right: 21px;
    }
    .anticon-check {
      color: var(--mainColor);
      position: absolute;
      right: 10px;
    }
  }
  .ant-select-item-option-selected,
  .ant-select-item-option-active {
    background-color: transparent !important;
    .ant-select-item-option-content {
      background-color: var(--mainColorLighter);
      color: var(--mainColor);
    }
  }
}

.my_default_custom_arrow {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  height: 12px;
  display: flex;
  inset-inline-end: 22px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  .anticon {
    transition: transform 0.3s;
  }
}
