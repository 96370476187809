.profile-tabs {
  .tab-info-item-wrapper {
    padding: 13px 11px;
    border-bottom: 1px solid var(--borderLineColor);
    &:last-child {
      border-bottom: none;
    }
    .mat-dropdown-trigger-container {
      min-width: 78px;
      max-width: 78px;
      height: 22px;
      border: 1px solid var(--borderLineColor);
      border-radius: 100px;
      padding: 0;
      .drop-menu-label-value {
        margin-left: 0;
        .drop-menu-value {
          font-size: 10px;
          line-height: 12.36px;
          font-weight: bold;
          padding: 0 2px 0 8px;
          color: #5cca97;
        }
      }
      .anticon-down {
        font-size: 8px;
        color: var(--thridyTextColor);
        padding-right: 6px;
      }
      &.tab-info-add-tag {
        cursor: pointer;
        min-width: 22px;
        max-width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px 0 0 4px;
        border-style: dashed;
      }
    }
    .tab-info-icon-with-text {
      > * {
        margin-right: 0;
      }
      .drop-menu-label {
        padding-left: 7px;
        color: var(--textColor);
        font-size: 14px;
        line-height: 17.3px;
        font-weight: 600;
      }
      .drop-menu-label-second {
        padding-top: 7px;
      }
      .tab-info-touchpoints {
        padding: 0px 0 0 7px;
        width: 100%;
        .drop-menu-label {
          padding-left: 0;
          margin-bottom: 8px;
        }

        .border-general-width {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-end;
          .border-item {
            margin: 4px 0px 0px 8px;
            min-height: 22px;
            padding: 4px 10px 6px 10px;
            background-color: var(--lighter);
            border-radius: 100px;
            color: var(--thridyTextColor);
            font-size: 10px;
            line-height: 12.36px;
            font-weight: 600;
            display: flex;
            align-items: center;
            span {
              word-break: break-all;
            }
            .tag-point {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              margin: 1px 4px 0 0;
            }
          }
        }
      }
    }
    .tab-info-auto-detected-language {
      > * {
        margin-right: 0;
      }

      font-size: 10px;
      color: var(--thridyTextColor);
      margin-right: 5px;
      white-space: nowrap;
      line-height: 12.36px;
    }
  }
  .tab-info-interests-slider {
    padding: 13px 11px;
    position: relative;
    border-bottom: 1px solid var(--borderLineColor);
    .slider-row {
      .info-value {
        display: flex;
        align-items: center;
        .icon-with-titile {
          color: var(--thridyTextColor);
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
          padding-left: 7px;
          .slider-header {
            color: var(--textColor);
            font-size: 14px;
            line-height: 17.3px;
            font-weight: 600;
            margin-bottom: 8px;
          }
          .border-item {
            margin: 4px 8px 0 0;
            height: 22px;
            padding: 4px 10px;
            background-color: var(--lighter);
            border-radius: 100px;
            color: var(--thridyTextColor);
            font-size: 10px;
            line-height: 12.36px;
            font-weight: 600;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .tab-info-item-wrapper {
      padding: 0;
      border-style: none;
    }
    .slick-arrow {
      position: absolute;
      right: 0px;
      display: flex !important;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      background-color: var(--white);
      z-index: 1;
      top: 20px;
      box-shadow: 0px 3px 5px 1px rgb(83 85 128 / 15%);
      &.slick-prev {
        left: auto;
        right: 17px;
      }
      &:before {
        display: none;
      }
      &:hover {
        background-color: var(--lighter);
      }
    }
    .tab-info-icon-with-text {
      > * {
        margin-right: 0;
      }

      .drop-menu-label {
        line-height: 17.3px;
        font-weight: 600;
      }

      .tab-info-touchpoints {
        padding: 7px 0 0 7px;
        .drop-menu-label {
          padding-left: 0;
          margin-bottom: 8px;
        }
        .vehicle-name {
          font-size: 12px;
        }
        .vehicle-id {
          color: var(--secondaryTextColor);
          font-size: 10px;
          span {
            margin-right: 12px;
          }
        }
      }
    }
    .post-purchase-history {
      span {
        font-size: 10px;
        font-weight: 600;
        &:first-child {
          color: #9daab2;
          width: 90px;
        }
        &:last-child {
          color: var(--thridyTextColor);
        }
      }
    }
  }
}

.message-info-tab-select-location-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 13px 11px;
  border-bottom: 1px solid var(--borderLineColor);

  .mat-select-wrapper {
    width: calc(100% - 30px);
    .mat-select-sub-wrapper {
      border-style: none;
      padding-right: 0;
      height: 30px;
      .ant-select-arrow {
        right: 0;
      }
    }
  }

  .mat-select.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 30px !important;
      padding-left: 7px;
      .ant-select-selection-item {
        line-height: 30px;
        color: var(--textColor);
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
