.my_default_input_number {
  &.grey {
    background-color: var(--newBackgroundGrey);
    border: 1px solid var(--newGrey);
    border-radius: 8px;
    height: 44px;
    padding: 12px 16px;
    outline: none;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
    &:focus {
      border-color: var(--mainColor);
    }
    // &.ant-input-status-error,
    // &.ant-input-status-error:focus,
    // &.ant-input-status-error:hover {
    //   background-color: var(--newBackgroundGrey) !important;
    //   box-shadow: none;
    //   border-color: #ff4d4f !important;
    // }
    // &.ant-input-affix-wrapper-focused {
    //   border-color: var(--mainColor) !important;
    // }
    // input {
    //   font-weight: 500;
    //   font-size: 16px;
    //   color: var(--black);
    // }
    // .ant-input-number-input {
    //   padding: 0;
    // }
  }
}

.ant-form-item-has-error {
  .my_default_input_number {
    &.grey {
      background-color: var(--newBackgroundGrey) !important;
      box-shadow: none;
      border-color: #ff4d4f !important;
    }
  }
}
