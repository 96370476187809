.my_default_custom_time_picker {
  &--inputs {
    border-radius: 8px;
    border: 1px solid var(--newGrey);
    background-color: var(--newBackgroundGrey);
    height: 44px;
    input {
      background-color: var(--newBackgroundGrey);
      padding: 3px 8px;
      height: 100%;
      border-style: none;
      border-radius: 8px;
      width: 40px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      &:focus {
        border: 2px solid var(--mainColor);
      }
    }
    .point {
      margin-bottom: 4px;
    }
  }
}

.my_default_opening_hours_divider {
  width: 11px;
  height: 2px;
  min-width: 11px;
  background-color: var(--secondaryTextColor);
  border-radius: 100px;
  margin: 0;
}
