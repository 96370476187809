

.livechat-appearance-wrapper {
  .my-page {
    max-width: 1090px;
  }
  .form-chatboxPreview-wrapper {
    display: flex;
    width: 100%;
    .ant-form {
      &.mat-form {
        width: 100%;
        background-color: var(--white);
        box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
        border-radius: 8px;
        overflow: hidden;
        padding: 36px;
        .mat-form-radio-group {
          justify-content: flex-start;
          margin-top: 10px;
        }
      }
    }
  }
  .image-info {
    margin-left: 10px;
    margin-top: 30px;
    h6 {
      width: max-content;
      color: var(--secondaryTextColor);
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 400;
      width: 100%;
    }
    .remove-image-button {
      background-color: transparent;
      border-style: none;
      padding: 0;
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      margin-bottom: 3px;
      color: var(--lightRed);
    }
  }
  .uploaded-image {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d0d1dd;
    overflow: hidden;
    object-fit: cover;
  }
  .ant-upload {
    &.ant-upload-select {
      &.ant-upload-select-picture-card {
        height: auto;
        width: auto;
        margin-top: 10px;
        margin-bottom: 0;
        > .ant-upload {
          display: block;
        }
      }
    }
  }
  .mat-upload {
    .ant-upload-select-picture-card {
      background-color: var(--white);
    }
    .ant-upload-select {
      border: none !important;
      &:hover {
        border: none;
      }
    }
  }
  .ant-upload-wrapper {
    &.ant-upload-picture-card-wrapper {
      .ant-upload.ant-upload-select {
        width: auto;
        height: auto;
        border-radius: 50%;
      }
    }
  }
  .upload-image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--borderLineColor);
    padding-bottom: 20px;
    .upload-image-edit {
      position: absolute;
      bottom: -5px;
      right: 5px;
    }
    .ant-row {
      &.ant-form-item {
        &.mat-form-item {
          width: auto;
          margin-bottom: 0;
        }
      }
    }
  }
  .radio-appearance-wrapper {
    border-bottom: 1px solid var(--borderLineColor);
    margin-bottom: 20px;
  }
  .mat-form-radio-group {
    width: 200px;
  }
  .color-appearance-wrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--borderLineColor);
    margin-bottom: 20px;
    padding-bottom: 20px;
    .mat-form-item {
      width: auto;
      margin-bottom: 0;
    }
    .ant-row {
      width: 160px;
    }
    .circle-radio-button {
      margin-right: -5px;
      .outside-label {
        display: none;
      }
    }
    .color-pick-item {
      padding-right: 10px;
    }
    &.green {
      .ant-radio-inner {
        background-color: #39aa5d;
        border-color: #39aa5d;
      }
    }
    &.red {
      .ant-radio-inner {
        background-color: #f16565;
        border-color: #f16565;
      }
    }
    &.blue {
      .ant-radio-inner {
        background-color: #658cf1;
        border-color: #658cf1;
      }
    }
    &.yellow {
      .ant-radio-inner {
        background-color: #f1d265;
        border-color: #f1d265;
      }
    }
    &.purple {
      .ant-radio-inner {
        background-color: #9a65f1;
        border-color: #9a65f1;
      }
    }
    .color-picker-item-wrapper {
      display: flex;
      padding-top: 10px;
    }
  }
  .inputs-appearance-wrapper {
    display: flex;
    border-bottom: 1px solid var(--borderLineColor);
    margin-bottom: 20px;
    .input-item {
      padding-right: 20px;
      width: 120px;
    }
  }
  .widget-position-wrapper {
    display: flex;
    width: 100px;
    justify-content: space-between;
    margin-top: 5px;
  }
  .widget-position {
    margin-right: 20px;
    cursor: pointer;
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid var(--borderLineColor);
    &.active {
      border: 1.5px solid var(--mainColor);
    }

    &.active2 {
      border: 2px solid var(--mainColor);
    }
  }
}

.appearance-edit-color-wrapper {
  font-size: 11px;
  color: var(--secondaryTextColor);
  display: flex;
  align-items: center;
  font-weight: 400;
}

.conic-gradient {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #9a65f1 0deg,
    #484cc0 76.25deg,
    #38cf59 149.37deg,
    #fae631 230deg,
    #f84c4c 288.12deg,
    #ea4cf8 355.62deg,
    #9a65f1 360deg
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1290px) {
  .livechat-appearance-wrapper {
    .form-chatboxPreview-wrapper {
      flex-direction: column;
      .ant-form {
        &.mat-form {
          max-width: 100%;
          overflow: visible;
        }
      }
      > .chatbox-preview-light-theme-v2 {
        padding-bottom: 100px;
        margin: 0 auto;
      }
    }
  }

  .matador-connect-settings-chat-wrapper {
    margin-left: 0;
    margin-top: 32px;
    .chatbox-preview-v2 {
      padding: 36px 36px 100px 36px;
    }
  }
}

@media (max-width: 575px) {
  .livechat-appearance-wrapper .ant-form .ant-form-item .ant-form-item-label,
  .livechat-appearance-wrapper .ant-form .ant-form-item .ant-form-item-control {
    flex: none;
  }

  .livechat-appearance-wrapper {
    .inputs-appearance-wrapper {
      .input-item {
        padding-right: 10px;
      }
    }
    .color-appearance-wrapper {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
      .ant-row {
        margin-bottom: 10px;
      }
    }
  }
}
