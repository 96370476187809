.integration-item-wrapper {
  background-color: var(--white);
  border-radius: 12px;
  border: 1px solid var(--newGrey);
  padding: 20px;
  max-width: 580px;
  width: 100%;
}

.integration-item-header {
  display: flex;
  border-bottom: 1px solid var(--newGrey);
  padding-bottom: 20px;
  margin-bottom: 15px;
  .anticon {
    height: 40px;
    width: 40px;
    min-width: 40px;
    background-color: var(--white);
    border: 1px solid var(--newGrey);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
}

.integration-item-header-titles {
  width: 90%;
  overflow-wrap: break-word;
  white-space: normal;
  p {
    font-size: 16px;
    font-weight: 600;
    color: var(--textColor);
    line-height: 19.09px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: var(--thridyTextColor);
    line-height: 20px;
    margin-top: 8px;
    display: block;
    min-height: 40px;
  }
}

.location-integration {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.crm_select_label {
  display: flex;
  flex-direction: 'row';
  align-items: 'center';
  .crm_select_info_icon {
    display: flex;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
  }
}
.crm_select_tooltip_wrapper {
  .crm_select_tooltip_title {
    font-size: 14px;
    font-weight: 700;
    color: var(--white);
  }
  .crm_select_tooltip_description {
    font-size: 12px;
    font-weight: 400;
    color: var(--newLightGrey);
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.integration-item-footer-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footer-connected-account {
    display: flex;
    flex-direction: column;
    span {
      font-size: 14px;
      line-height: 16.71px;
      &:nth-child(1) {
        color: var(--secondaryTextColor);
        font-weight: 400;
      }
      &:nth-child(2) {
        color: var(--textColor);
        font-weight: bold;
      }
    }
  }
  .mat-switch-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .switch-label {
      padding-bottom: 0;
      padding-left: 10px;
    }
  }
  .modal-btn {
    .modal-settings-icon {
      cursor: pointer;
      height: 45px;
      width: 45px;
      background-color: var(--mainColorLighter);
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled {
        opacity: 0.5;
      }
    }
    .ant-badge-dot {
      background-color: var(--lightRed);
      width: 10px;
      height: 10px;
      position: absolute;
      top: 2px;
      left: -8px;
      border-width: 1px;
      border-style: solid;
      border-color: var(--white);
      border-image: initial;
      border-radius: 50%;
      animation: 2s linear 0s infinite normal none running animate;
    }
  }
  .facebook-item-row {
    width: auto;
    > * {
      margin-right: 0px;
    }
    .modal-btn {
      margin-right: 12px;
    }
  }
}

body:has(.google-business-integration) {
  background-color: var(--white);
  overflow: hidden;
}

.integration-item-footer {
  iframe {
    width: 100%;
    height: 40px;
    border-style: none;
  }
  .location-white-background {
    height: 40px;
    position: relative;
  }
}

.google-business-integration {
  .mat-btn {
    border-radius: 8px !important;
  }
}

.facebook-block {
  .mat-select-wrapper-secondary {
    + .mat-select-wrapper-secondary {
      margin-top: 20px;
    }
  }
}

.facebook-integration-modal {
  .modal-content-wrapper {
    overflow: visible;
  }
}

.facebook-item-row {
  .kep-login-facebook {
    font-size: 14px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-shadow: none;
    border-style: none;
    width: fit-content;
    height: 40px;
    border-radius: 8px !important;
    box-shadow: none;
    padding: 0 15px;
    line-height: normal;
    display: flex;
    text-transform: capitalize;
  }
}

.business-disconnect-popup {
  left: auto !important;
  right: -118px;
  top: -147px !important;
}

.loc-navigation-page-container {
  .matador-connect-page-wrapper {
    .business-disconnect-popup {
      .mat-btn {
        border-radius: 100px;
      }
    }
  }
}

.google-auth-warning {
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  white-space: pre-line;
}

.google-auth-warning {
  .anticon {
    font-size: 16px;
    position: relative;
    margin-right: 6px;
  }
}

.integration-finish {
  font-size: 10px;
  position: absolute;
  white-space: nowrap;
  right: 0;
  color: var(--red);
}

.integration-item-footer-item-second {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--newGrey);
}

.integration-item-footer-item-second {
  .mat-input-affix-secondary-wrapper {
    margin-right: 10px;
    height: 40px;
  }
  .secondary-items-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.google-business-list-checkbox {
  .mat-radio-group {
    .ant-radio-wrapper {
      + .ant-radio-wrapper {
        margin-top: 10px;
      }
    }
  }
}

.google-business-address {
  font-size: 12px;
  font-weight: 400;
  color: var(--secondaryTextColor);
}

.touchpoint-integration-buttons {
  width: auto;
  justify-content: flex-end;
  margin-top: 5px;
  > * {
    margin-right: 12px;
  }
  .mat-btn {
    + .mat-btn {
      margin-left: 0;
    }
  }
}

.touchpoint-integration {
  flex-wrap: wrap;
  .footer-connected-account {
    + .footer-connected-account {
      + .touchpoint-integration-buttons {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .integration-item-footer-item {
    flex-wrap: wrap;
    margin-top: -10px;
    > * {
      margin-top: 10px;
    }
    .facebook-item-row {
      width: 100%;
      justify-content: space-between;
    }
  }

  .integration-finish {
    right: auto;
    left: 0;
  }

  .touchpoint-integration-buttons {
    flex-direction: column;
    width: 100%;
    .mat-btn {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      + .mat-btn {
        margin-top: 10px;
      }
      + div {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
