.loc-navigation-page-container {
  .matador-connect-page-wrapper {
    padding: 20px 32px 75px 31px;
    background-color: var(--newBackgroundGrey);
    border-radius: 0;
    height: 100%;
    margin-bottom: 0;
    textarea.ant-input {
      height: 94px;
      overflow-y: auto;
      border-radius: 8px;
    }
    .mat-btn {
      border-radius: 8px !important;
    }
  }
}

.matador-connect-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: var(--textColor);
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    color: var(--thridyTextColor);
    display: block;
    line-height: 16.71px;
    margin-top: 7px;
  }
}

.matador-connect-list-right-part {
  display: flex;
  align-items: center;
}

.matador-connect-mat-list-wrapper {
  width: 100%;
  box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
  border-radius: 8px;
  background-color: var(--white);
  margin-top: 0;
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    .matador-connect-image-title,
    .matador-connect-date-title {
      padding: 13px;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 90px;
    }
    .matador-connect-date-title {
      width: calc(100% + 140px);
    }
    .matador-connect-image-title {
      .item-image {
        height: 50px;
        width: 74px;
        border-radius: 13px;
        object-fit: contain;
        background-color: var(--lighter);
      }
    }
    .matador-connect-action-container {
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 30px;
    }
    .edit-delete-panel {
      display: flex;
      align-items: center;
      margin-left: 10px;
      width: auto;
      .edit-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin-right: 0px;
        &:hover {
          background-color: var(--mainColorLighter);
        }
      }
    }
    .mat-switch-wrapper {
      margin-right: 10px;
    }
  }
}

.matador-connect-mat-list-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--newGrey);
}

.matador-connect-mat-list-header-item {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  padding: 13px;
  &:last-child {
    width: 10%;
  }
}

.matador-connect-list-edit-delete {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.matador-connect-image-with-switch {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.matador-connect-list-one-item-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  .list-item {
    &:last-child {
      .matador-connect-list-one-item-wrapper {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.matador-connect-greeting-default {
  white-space: nowrap;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  &.current {
    cursor: default;
    color: var(--green);
  }
  &.active {
    color: var(--mainColor);
  }
}

@media screen and (max-width: 1190px) {
  .matador-connect-mat-list-wrapper {
    .list-item {
      .matador-connect-image-title {
        word-break: break-all;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .loc-navigation-page-container {
    .matador-connect-page-wrapper {
      padding: 20px;
    }
  }

  .matador-connect-mat-list-wrapper {
    .list-item {
      .matador-connect-image-title {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      .matador-connect-image-title,
      .matador-connect-date-title {
        padding: 10px;
      }
      .matador-connect-action-container {
        flex-direction: column-reverse;
        align-items: flex-end;
        .mat-dropdown-trigger-container {
          justify-content: flex-end;
        }
      }
    }
  }

  .matador-connect-image-with-switch {
    margin-right: 0;
  }

  .matador-connect-mat-list-header-item {
    padding: 10px;
  }

  .matador-connect-greeting-default {
    margin-right: 0px;
    margin-top: 10px;
  }
}
