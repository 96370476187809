.loc-navigation-page-container {
  .add-edit-container {
    background-color: var(--lighter);
    border-radius: 0;
    margin: 0;
    padding: 25px 0;
  }
}

.add-edit-view {
  background: var(--white);
  box-shadow: 2px 7px 20px rgba(44, 52, 94, 0.1);
  border-radius: 8px;
  margin: 0 auto;
  width: 100%;
  padding: 32px 78px 42px 78px;
}

.add-edit-header {
  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: var(--textColor);
    text-align: center;
    margin-bottom: 26px;
  }
}

.empty-dropzone-state {
  padding: 40px;
  p {
    margin-top: 14px;
    font-size: 13px;
    font-weight: 200;
    line-height: 20px;
    color: var(--secondaryTextColor);
    span {
      font-weight: 600;
      color: var(--mainColor);
    }
  }
}

.uploaded-media {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  max-width: 200px;
  img,
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.edit-area__inputs {
  .big-upload-wrapper {
    .ant-upload-select {
      width: auto !important;
      height: auto !important;
      background-color: var(--newLightGrey) !important;
      border: 1px dashed var(--newGrey) !important;
    }
  }
}

.upload-image-delete-icon {
  .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1;
  }
}

@media screen and (max-width: 991px) {
  .add-edit-container {
    .add-edit-view {
      padding-left: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .stories-dprozone-container {
    padding: 32px 28px 42px 10px;
  }
}

@media screen and (max-width: 479px) {
  .uploaded-media {
    padding: 20px;
    width: 100%;
  }

  .upload-image-delete-icon {
    .anticon {
      top: 30px;
      right: 30px;
    }
  }
}
