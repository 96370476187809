.my_default_form_item {
  margin-bottom: 24px;
  .ant-form-item-label {
    line-height: 18px !important;
    padding: 0 !important;
    margin-bottom: 12px !important;
    label {
      color: var(--textColor);
      text-transform: capitalize;
      font-weight: 500;
      font-size: 14px !important;
    }
  }
  &.last {
    margin-bottom: 0px;
  }
  &.first {
    margin-top: 24px;
  }
  &.disable_margins {
    margin: 0;
  }
}
