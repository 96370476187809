.location-calendar-invites-wrapper {
  padding: 20px 32px 10px 30px;
  background-color: var(--newBackgroundGrey);
  .auto-reply-items-list {
    padding-right: 0;
    max-height: fit-content;
    .mat-block-with-label {
      .mat-label {
        label {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          color: var(--black);
          margin-bottom: 10px;
        }
      }
    }
  }
  &.aibot-template-edit-organization-page {
    .ai-bot-engagement-percent-with-icon {
      justify-content: center;
      margin-left: 8px;
      .anticon {
        margin-bottom: 0;
      }
    }
    .auto-reply-items-list-wrapper {
      background-color: transparent;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
    }
    .aibot-reply-item {
      .row-div-container {
        height: auto;
        padding: 12px 0;
      }
    }
    .aibot-reply-item-panel-wrapper {
      margin-left: 20px;
      border-radius: 16px;
      box-shadow: none;
      padding: 16px 20px;
    }
  }
  .same-day-reminder-switch {
    background-color: var(--white);
    border-radius: 16px;
    padding: 16px 20px;
    grid-area: same-day;
    margin-left: 20px;
    margin-bottom: 16px;
    .mat-switch-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .switch-label {
        padding-bottom: 0;
        padding-left: 7px;
      }
    }
  }
  .footer-connected-account {
    min-width: 160px;
    span {
      font-size: 10px;
      font-weight: 700;
      &:first-child {
        color: var(--secondaryTextColor);
      }
      &:nth-child(2) {
        color: var(--textColor);
        margin-left: 2px;
      }
    }
  }
  .create-drip-campaign {
    grid-template-areas:
      'list-replies  same-day'
      'list-replies  reply-panel';
  }
  .add-aibot-reply-item {
    &.mat-btn-primary {
      background-color: var(--white);
      border-radius: 0 0 16px 16px !important;
      width: 100%;
      padding: 20px;
      height: auto;
      font-size: 16px;
      font-weight: 600;
      &:not(:disabled):not(.mat-btn-disabled):hover {
        background-color: var(--white);
      }
    }
    .plus-circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 17px;
    }
  }
}

.calendar-reminder-first-item {
  border-radius: 16px 16px 0 0;
  padding: 10px 20px 5px;
  .mat-label {
    padding-top: 10px;
  }
}

.calendar-confirmation-item {
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 16px;
}

.confirmation-and-reminders-item {
  background-color: var(--white);
  &:not(:first-child):not(:nth-child(2)) {
    padding: 5px 20px;
  }
  &:nth-child(6) {
    padding-bottom: 20px !important;
    border-radius: 0 0 16px 16px;
  }
}

@media screen and (max-width: 991px) {
  .location-calendar-invites-wrapper {
    padding: 20px;
    .create-drip-campaign {
      grid-template-areas:
        'same-day'
        'list-replies'
        'reply-panel';
    }
    &.aibot-template-edit-organization-page {
      .create-drip-campaign {
        grid-template-columns: 100% 100%;
      }
      .auto-reply-items-list-wrapper {
        border-left: none;
      }
      .aibot-reply-item-panel-wrapper {
        margin-left: 0;
        margin-top: 16px;
      }
    }
    .same-day-reminder-switch {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .location-calendar-invites-wrapper {
    &.aibot-template-edit-organization-page {
      .organization-new-list-right-part {
        padding: 10px 20px;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .location-calendar-invites-wrapper {
    .create-drip-campaign {
      display: grid;
    }
  }
}
