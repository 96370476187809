

.livechat-questions-wrapper {
  .my-page {
    max-width: none;
  }
}

.livechat-questions-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  .left-content {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      color: var(--secondaryTextColor);
      font-weight: 400;
      margin-right: 5px;
    }
    span {
      background-color: #f8a326;
      color: var(--white);
      padding: 2px 10px;
      border-radius: 100px;
      font-weight: 700;
    }
  }
  .right-content {
    display: flex;
    justify-content: flex-end;
  }
}

.livechat-question-item-wrapper {
  border: 1px solid var(--borderLineColor);
  border-radius: 9px;
  padding: 20px;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 16%);
  }
  .row-div-container {
    width: auto;
  }
  .btn-block-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    cursor: pointer;
    margin-right: 0;
    &:hover {
      background-color: var(--mainColorLighter);
    }
  }
  .question-title {
    font-size: 16px;
    font-weight: 500;
    color: var(--textColor);
  }
}

.livechat-question-edit {
  .question-edit-info {
    background-color: #f8a3260d;
    height: 40px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    color: #f8a326;
    border-radius: 3px;
    margin-bottom: 30px;
  }
  .my-page {
    padding: 0;
  }
  .select-user-assignement {
    width: auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .drop-down-trigger-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      border: 1px solid var(--newGrey);
      background-color: var(--newLightGrey);
      border-radius: 8px;
      padding: 0 10px;
      height: 50px;
      cursor: pointer;
      width: 100%;
      .anticon {
        svg {
          fill: #79828f;
        }
      }
      .title-item {
        margin-right: 8px;
        white-space: nowrap;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 400;
        color: #79828f;
        padding-right: 5px;
        height: 26px;
        line-height: 26px;
        cursor: pointer;
      }
    }
  }
  .list-variables {
    margin-bottom: 0;
  }
}
