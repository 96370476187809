

.custom-color-picker {
  display: flex;
  justify-content: center;
}

.mat-react-color.sketch-picker {
  box-shadow: none !important;
}

.mat-color-trigger {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  background-color: var(--secondaryTextColor);
  cursor: pointer;
  border: 1px solid var(--grey);
}
