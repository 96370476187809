.message-tab-pane-wrapper {
  overflow-x: hidden;
  overflow: visible;
  .tab-note-header {
    color: var(--black);
    font-size: 14px;
    font-weight: 700;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .mat-btn {
      height: 30px;
      width: fit-content;
    }
  }
  .ant-list-footer {
    padding: 12px 20px;
  }
  .note-list-item {
    padding: 10px 20px;
    span {
      font-size: 12px;
      color: var(--secondaryTextColor);
      font-weight: 600;
    }
    .edit-with-delete {
      display: flex;
      align-items: center;
      .anticon {
        svg {
          path {
            fill: var(--secondaryTextColor);
          }
        }

        & + .anticon {
          margin-left: 5px;
        }
      }
    }
  }
}

.profile-tabs {
  border-radius: 12px 12px;
  background-color: var(--white);
  box-shadow: 2px 2px 40px rgb(83 85 128 / 10%);
  .ant-tabs {
    overflow: visible;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
    .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-ink-bar {
        background-color: var(--mainColor);
        width: 100px !important;
        transform: translateX(-54%) !important;
      }
      .ant-tabs-tab {
        width: 50%;
        margin: 0;
        padding: 20px 0 12px;

        .ant-tabs-tab-btn {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--thridyTextColor);
          font-size: 12px;
          font-weight: bold;

          .anticon {
            margin-right: 6px;
          }

          span + span {
            display: none;
          }
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--mainColor);
            span + span {
              display: block;
              margin-right: 3px;
            }

            svg {
              path {
                fill: var(--mainColor);
              }
            }
          }
        }

        &.ant-tabs-tab-disabled {
          opacity: 0.5;
        }
      }
    }
  }
}

.message_right_panel-drawer {
  .ant-drawer-body {
    .message-tab-pane-wrapper {
      overflow-y: visible;
      height: 100%;
    }
  }
}
