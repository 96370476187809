.loc-navigation-page {
  display: flex;
  border-top: 1px solid var(--borderLineColor);
  background-color: var(--white);
  height: 100%;
  overflow: hidden;
  .loc-side-menu-layout-wrapper {
    .ant-menu-inline-collapsed {
      width: auto;
    }
    .ant-menu-inline {
      border-right: none;
    }
    .ant-menu-item {
      height: 52px;
      margin: 0;
      line-height: normal;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 20px !important;
      border-radius: 0;
      &:active {
        background-color: #f6f7fb;
      }
      &.ant-menu-item-selected {
        .ant-menu-title-content {
          font-weight: bold;
        }
      }
      .ant-menu-title-content {
        color: var(--textColor);
        font-weight: 400;
        font-size: 16px;
      }
    }
    .ant-menu-submenu-title {
      padding-left: 20px !important;
      &:hover {
        color: var(--textColor);
      }
      &:active {
        color: var(--textColor);
        background-color: var(--white);
      }
    }
    .ant-menu-submenu {
      .ant-menu-item {
        padding-left: 50px !important;
      }
    }
    .ant-menu-item-selected {
      background-color: #f6f7fb;
      &::after {
        display: none;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        border-radius: 0 100px 100px 0;
        border-right: 3px solid var(--mainColor);
        height: 100%;
      }
    }
  }
  .ant-menu-submenu-selected {
    > .ant-menu-submenu-title {
      color: var(--textColor);
    }
  }
  .ant-dropdown-trigger {
    &.active {
      border-bottom: 3px solid var(--mainColor);
    }
  }
}

.loc-side-menu-layout-wrapper {
  max-width: 257px;
  width: 100%;
  background-color: var(--white);
  border-right: 1px solid var(--borderLineColor);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .icon-with-text {
    display: flex;
    align-items: center;
    margin: 17px 0;
    padding: 0 0 0 20px;
    .icon-wrapper {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
    }
    .location-name {
      font-weight: 500;
      font-size: 12px;
      border-left: 1px solid #d1d2de;
      color: #a3a6be;
      padding-left: 8px;
    }
  }
  .side-bar-layout {
    background-color: transparent;
  }
}

.ant-menu-submenu-arrow {
  &::before,
  &::after {
    background-color: var(--textColor);
  }
}

.loc-navigation-page-container {
  width: 100%;
  overflow-y: auto;
  .my-page-full {
    width: 100%;
    max-width: 100%;
  }
}

.dropdown-matador-connect-overlay {
  .ant-dropdown-menu-item-selected {
    background-color: var(--white);
    .ant-dropdown-menu-title-content {
      color: var(--mainColor);
    }
  }
  .ant-dropdown-menu-title-content {
    color: var(--textColor);
  }
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 5px 12px;
      .ant-dropdown-menu-title-content {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .loc-navigation-page-container {
    height: calc(100vh - 108px);
    .add-edit-container {
      padding: 0;
    }
    .my-page {
      padding-right: 20px;
    }
  }

  .loc-side-menu-layout-wrapper {
    height: calc(100vh - 108px);
  }
}

@media screen and (max-width: 767px) {
  .loc-navigation-page {
    flex-direction: column;
    .loc-side-menu-layout-wrapper {
      .ant-menu-item-icon {
        position: absolute;
      }
      .ant-menu-item {
        padding: 7px 10px 10px !important;
        height: 40px;
        justify-content: center;
        &.active {
          background-color: var(--newLightGrey);
        }
        .ant-menu-title-content {
          font-size: 0;
          overflow: hidden;
          margin: 0;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .loc-side-menu-layout-wrapper {
    overflow-y: hidden;
    max-width: 100%;
    height: auto;
    .side-bar-layout {
      display: flex;
      border-right: 0px solid transparent;
      border-bottom: 1px solid var(--borderLineColor);
    }
  }
  .loc-navigation-page .loc-side-menu-layout-wrapper .ant-menu-item-selected::before,
  .loc-navigation-page .loc-side-menu-layout-wrapper .ant-menu-item.active::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-right: 0px solid transparent;
    border-bottom: 3px solid var(--mainColor);
    border-radius: 0;
  }

  .loc-navigation-page-container {
    height: calc(100vh - 149px);
  }
}

@media screen and (max-width: 767px) {
  .loc-side-menu-layout-wrapper {
    .icon-with-text {
      padding: 0 20px;
      margin-bottom: 15px;
      display: none;
    }
  }
}
