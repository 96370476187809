.my_default_modal {
  .ant-modal-title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    .anticon {
      margin-right: 10px;
    }
  }
  .ant-modal-header {
    padding: 20px 30px !important;
    border-radius: 9px 9px 0 0 !important;
    border-bottom: 1px solid var(--borderLineColor) !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
  &--content_wrapper {
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: overlay;
    overflow-x: hidden;
    padding: 20px 30px;
  }
  .ant-modal-content {
    border-radius: 9px !important;
    padding: 0px !important;
  }
  .ant-modal-footer {
    padding: 12px 32px !important;
    border-radius: 0 0 9px 9px;
    display: flex;
    margin-top: 0;
    justify-content: flex-end;
    border-top: 1px solid var(--borderLineColor) !important;
    .mat-btn {
      max-width: 130px;
    }
  }
}

.ant-modal-root {
  .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.ant-modal-mask {
  z-index: 10000;
}

.ant-modal-wrap {
  z-index: 10000;
}

.Toastify__toast-container {
  z-index: 99990;
}

@media screen and (max-width: 479px) {
  .my_default_modal {
    .ant-modal-footer {
      display: flex;
      justify-content: center;
    }
  }
}
