.location-edit-wrapper {
  .mat-block-with-label {
    margin-bottom: 25px;
    .manage-users {
      display: flex;
    }
    .current-gmail-account {
      font-size: 13px;
    }
  }
  .mat-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
  .ant-form {
    &.mat-form {
      width: 100%;
      max-width: 472px;
      background-color: var(--white);
      box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
      border-radius: 8px;
      padding: 36px;
    }
  }
}

@media screen and (max-width: 991px) {
  .location-edit-wrapper {
    .ant-form {
      &.mat-form {
        max-width: 1000%;
      }
    }
  }
}
