.login-page-wrapper {
  background-color: var(--white);
}

.login-page-main-row {
  height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.login-page-card-wrapper {
  border-radius: 24px;
  min-height: 556px;
  .ant-card-body {
    padding-right: 0;
  }
  .ant-form {
    max-width: 300px;
    width: 100%;
  }
}

.login-page-left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.login-page-text-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-page-title {
  font-size: 32px;
  font-weight: 600;
}

.login-page-describe {
  color: var(--secondaryTextColor);
  font-size: 16px;
  margin: 8px 0 30px;
  max-width: 300px;
  font-weight: 400;
}

.login-page-button-wrapper {
  margin-top: 24px;
}

.row-div-container.login-page-button-wrapper > * {
  margin-right: 0;
}

.login-page-right-side {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 50%;
  svg {
    width: 100%;
    height: 100%;
  }
}

.login-page-card-wrapper a,
.resend-code {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--mainColor);
  margin-top: -20px;
  cursor: pointer;
}

.ant-divider-inner-text {
  color: var(--secondaryTextColor);
}

.password-request-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.password-request-block-wrapper {
  max-width: 500px;
  width: 100%;
  box-shadow: 4px 26px 27px rgb(221 224 231 / 76%);
  margin-top: 40px;
  padding: 20px;
  border-radius: 9px;
  background-color: var(--white);
}

.password-request-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h6 {
    color: var(--secondaryTextColor);
    font-size: 12px;
    font-weight: 400;
    margin: 10px 0 30px;
  }
}

.password-request-validate-image {
  width: 60%;
}

.hint-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  width: 100%;
  max-width: 300px;
  + .hint-button {
    margin-top: 10px;
  }
  .mat-btn {
    width: 100%;
    height: 60px;
    box-shadow: 0px 2px 4px rgb(79 81 106 / 36%);
    transition: 0.3s;
    border-radius: 8px !important;
    border: 1px solid var(--mainColor) !important;
    &:hover {
      box-shadow: none;
      .icon-with-hint-number {
        .anticon {
          transform: translate(3px, 0);
        }
      }
    }
    .icon-with-hint-number {
      .anticon {
        transition: 0.3s;
      }
    }
  }
  .text-with-icon {
    margin-left: 15px;
    text-align: left;
    color: var(--mainColor);
    .anticon {
      position: absolute;
      right: 15px;
    }
  }
  p {
    font-size: 12px;
    margin-top: 4px;
    text-align: left;
    opacity: 0.7;
  }
}

.icon-with-hint-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.forgot-password-link {
  margin-top: -10px;
  color: var(--mainColor);
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &:hover {
    color: var(--mainColor) !important;
  }
}

.magic-link-btn {
  color: var(--purple) !important;
  border: 1px solid var(--purple) !important;
  width: 178px;
  height: 50px;
  border-radius: 60px;
}

.login-link-btn {
  width: 178px;
  height: 50px;
  border-radius: 60px;
}

.login-page-left-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -30px;
  background-color: transparent;
  box-shadow: none;
  .ant-card-body {
    padding: 0;
  }
  form {
    max-width: 376px;
  }
}

.ant-card {
  &.login-page-left-card {
    box-shadow: none;
  }
}

.login-page-right-card {
  width: 41%;
  border-radius: 24px;
  display: flex;
  height: 100%;
  align-self: center;
  img {
    display: flex;
    width: 100%;
    object-fit: contain;
    height: 100%;
    border-radius: 24px;
  }
}

.login-page-download-in-btn {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 60px;
  height: 40px;
  &:hover {
    color: var(--mainColor) !important;
    border-color: var(--mainColor) !important;
  }
  &:active {
    color: var(--mainColor) !important;
    border-color: var(--mainColor) !important;
  }
  &:focus {
    color: var(--mainColor) !important;
    border-color: var(--mainColor) !important;
  }
}

.password-request-validate-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.login-verification-cancel-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media screen and (max-width: 991px) {
  .login-page-left-card {
    justify-content: flex-start;
    margin-top: 0;
  }

  .login-page-text-wrapper {
    padding-top: 30px;
  }
}

@media screen and (max-height: 790px) {
  .login-page-right-card {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .login-page-wrapper {
    padding: 0;
    background-color: var(--white);
  }

  .login-page-card-wrapper {
    height: 100%;
    .ant-card-body {
      padding: 0 0 40px 0;
    }
  }

  .login-page-main-col {
    width: 100%;
    height: 100%;
  }

  .login-page-card-row {
    flex-direction: column-reverse;
  }

  .login-page-right-side,
  .login-page-left-side {
    width: 100%;
  }

  .login-page-right-side {
    .anticon {
      width: 100%;
    }
  }

  .login-page-white-logo {
    position: absolute;
    z-index: 1;
    top: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    .anticon {
      width: 30%;
    }
  }
}

@media screen and (max-width: 479px) {
  .login-page-left-side {
    padding: 0 15px;
  }

  .login-page-card-wrapper {
    .ant-form {
      max-width: 100%;
    }
    a {
      margin-top: 0;
      justify-content: center;
    }
  }

  .login-page-white-logo {
    top: 15px;
    .anticon {
      width: 50%;
    }
  }

  .password-request-wrapper {
    padding: 40px 20px;
  }

  .password-request-wrapper {
    .mat-form {
      .row-div-container {
        flex-direction: row !important;
        margin-top: 30px;
        justify-content: space-between;
      }
    }
  }

  .resend-code {
    margin-top: -5px;
  }

  .login-page-left-card {
    .ant-card-body {
      padding-top: 0px !important;
      width: 100%;
    }
  }

  .login-page-button-wrapper {
    flex-direction: column-reverse !important;
  }

  .forgot-password-link {
    margin-top: 0px;
  }

  .magic-link-btn {
    width: 330px;
  }

  .login-link-btn {
    width: 330px;
    margin-left: 0px !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 375px) {
  .login-page-left-card {
    align-items: normal !important;
  }
}
