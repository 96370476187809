.cta-preview-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding-top: 20%;
}

.cta-preview-content {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  max-width: 100%;
}

.cta-preview-icon {
  svg {
    height: 23px;
    width: 23px;
    margin-right: 3px;
  }
}

@media screen and (max-width: 497px) {
  .cta-preview-container {
    padding-top: 20px;
  }

  .cta-preview-content {
    position: relative;
  }
}
