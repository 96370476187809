.matador-connect-preview-default {
  background-color: var(--lighter);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 0%;
  padding-bottom: 10%;
  margin-bottom: 10%;
  border-radius: 8px;
  border: 1px solid var(--newGrey);
  padding-left: 2%;
}

#iframe-preview {
  max-width: 100%;
}

@media screen and (max-width: 1290px) {
  .matador-connect-preview-default {
    padding: 0;
    align-items: center;
    iframe {
      /* height: auto; */
      position: relative !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .matador-connect-preview-default {
    iframe {
      margin-top: 20px;
    }
    #iframe-preview-subscriber-growth {
      height: 800px;
    }
  }
}
