.my_default_upload_wrapper_big {
  border-radius: 12px;
  border: 1.5px dashed var(--mainColor);
  background-color: var(--mainColorLighter);
  overflow: hidden;
  height: 100%;
  display: flex;
  cursor: pointer;
  &:has(.ant-upload-disabled) {
    filter: grayscale(1);
    .my_default_upload_wrapper_big--text {
      max-width: 100%;
    }
  }
  &.ant-upload-wrapper {
    .ant-upload-list-picture-card {
      width: 100%;
      border-radius: 12px;
    }
    .ant-upload-select {
      width: 100%;
      background-color: transparent;
      border-style: none;
      border-radius: 12px;
      height: auto;
      .ant-upload {
        padding: 20px;
      }
    }
  }
  &--text {
    font-size: 14px;
    font-weight: 400;
    color: var(--thridyTextColor);
    max-width: 150px;
    text-align: center;
    &:has(span) {
      max-width: 450px;
      padding: 0 10px;
    }
  }
  &--item_wrapper {
    background-color: var(--mainColorLighter);
    padding: 24px;
    border-radius: 12px;
    &--inner {
      width: 176px;
      height: 117px;
      border-radius: 12px;
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      .ant-image {
        img {
          border-radius: 14px;
          width: 176px;
          height: 117px;
          object-fit: cover;
        }
        .ant-image-mask {
          border-radius: 14px;
        }
      }
      &-delete {
        z-index: 1;
        background-color: var(--white);
        padding: 8px;
        border-radius: 0px 12px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
      &.centered {
        margin: 0 auto;
      }
    }
  }

  &.ant-upload-picture-card-wrapper {
    border: 1.5px solid var(--mainColor);
    cursor: default;
    .ant-upload-list-picture-card {
      padding: 20px;
      gap: 12px !important;
      .ant-upload-select {
        border-radius: 12px;
        width: 170px;
        height: 117px;
        .ant-upload {
          padding: 5px;
          .ant-flex {
            gap: 10px !important;
          }
        }
        &:hover {
          border-color: var(--mainColor) !important;
        }
      }
      &.ant-upload-list {
        .ant-upload-list-item-container {
          width: auto;
          height: auto;
        }
      }
    }
    .my_default_upload_wrapper_big--item_wrapper {
      padding: 0;
      background-color: var(--white);
      &--inner {
        width: 170px;
        .ant-image {
          img {
            width: 170px;
          }
        }
      }
    }
  }
}

.img-crop-modal {
  .ant-btn.my_default_button {
    font-size: 16px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-shadow: none;
    border-style: none;
    width: fit-content;
    height: 44px;
    border-radius: 8px !important;
    box-shadow: none;
    padding: 0 32px;
    line-height: 24px;
    display: flex;
    &.my_default_button_primary {
      background-color: var(--mainColor);
      &:hover {
        background-color: var(--mainColor);
      }
      &:disabled {
        background-color: var(--mainColorLighter);
        color: var(--white);
      }
      .anticon-loading {
        color: var(--white);
      }
    }
    &.my_default_button_cancel {
      background-color: var(--white);
      font-size: 16px;
      line-height: 24px;
      color: var(--black);
      font-weight: 500;
      border: 1px solid rgba(229, 233, 242, 1);
      gap: 6px;
      &:hover {
        background-color: var(--lightgrey);
      }
    }
  }
}
