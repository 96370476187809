.popup-widget-wrapper {
  .text-with-edit-icon {
    &:nth-child(3) {
      textarea {
        font-weight: 600 !important;
      }
    }
  }
  .color-edit {
    &:first-child {
      width: fit-content;
      margin-left: auto;
    }
  }
  .bg-color-edit-icon-wrapper {
    span {
      width: 15px;
      height: 15px;
      border-radius: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 1px 5px rgb(0 0 0 / 19%);
    }
  }
}

.popup-language-switch {
  margin-bottom: 25px;
}

.popup-image-delete-icon {
  span {
    background-color: #408ff0;
    width: 15px;
    height: 15px;
    border-radius: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 1px 5px rgb(0 0 0 / 19%);
    position: absolute;
    top: -5px;
    right: -8px;
    z-index: 1;
    svg {
      width: 8.75px;
      height: 8.75px;
    }
  }
}

.popup-upload-container {
  width: fit-content;
  margin: auto;
  margin-top: 10px;
}
