.add-appointment-popover-overlay {
    max-width: 360px;
    width: 100%;
    .ant-popover-content {
      max-width: 360px;
      width: 100%;
  
      .ant-popover-title {
        background-color: var(--lightgrey);
        font-size: 16px;
        border-radius: 12px 12px 0 0;
        color: var(--purple);
        font-weight: 500;
        padding: 15px 19px;
        line-height: normal;
        border-style: none;
        max-width: 100%;
      }
  
      .ant-popover-inner {
        border-radius: 12px;
        box-shadow: 1px -1px 10px rgba(12, 6, 93, 11%);
        border: 1px solid var(--borderLineColor);
        padding: 0px;
        max-width: 100%;
  
        .ant-popover-inner-content {
          padding: 13px 20px 20px;
        }
      }
    }
}