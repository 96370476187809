.livechat-greeting-edit {
  .form-chatboxPreview-wrapper {
    display: flex;
    width: 100%;
    .ant-form {
      &.mat-form {
        width: 100%;
        max-width: 472px;
        background-color: var(--white);
        box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
        border-radius: 8px;
        overflow: hidden;
        padding: 36px;
      }
    }
  }
  .time-on-page-wrapper {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--borderLineColor);
    border-top: 1px solid var(--borderLineColor);
    padding: 20px 0;
    font-size: 12px;
    .ant-row {
      &.ant-form-item {
        &.mat-form-item {
          margin-bottom: 0;
          width: 100px;
          margin-right: 7px;
        }
      }
    }
    .row-div-container {
      align-items: baseline;
      background-color: rgb(120 84 246 / 5%);
      padding: 12px 16px;
      border-radius: 8px;
      justify-content: space-between;
      .mat-form-item-black {
        margin-bottom: 0;
        margin-right: 5px;
      }
      .mat-input-affix-secondary-wrapper {
        padding: 0 10px;
        height: 25px;
        background-color: var(--white);
        .mat-input-affix-secondary-wrapper {
          height: 22px;
        }
        .ant-input-number {
          .ant-input-number-input-wrap {
            input {
              text-align: end;
              font-size: 12px;
              height: 25px;
              line-height: 0px;
            }
          }
        }
      }
    }
  }
  .btn-block-item {
    margin: auto 0;
    margin-top: 40px;
  }
  .add-greeting-condition {
    &.mat-btn-primary {
      color: var(--mainColor);
      font-weight: 600;
      padding: 0;
      z-index: 1;
      background-color: transparent;
      margin-left: 0;
      margin-bottom: 20px;
      &:hover {
        background-color: transparent !important;
        color: var(--mainColor) !important;
      }
      span {
        display: flex;
        align-items: center;
      }
    }
    .plus-circle {
      margin-right: 10px;
      border-radius: 5px;
      color: var(--mainColor);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2px;
    }
  }
  .draggable-questions-wrapper {
    width: 100%;
    padding: 0;
    .draggable-question {
      display: flex;
      align-items: center;
      color: var(--textColor);
      font-weight: 600;
      word-break: break-all;
      margin-bottom: 0 !important;
      margin-top: 5px !important;
      user-select: none;
      padding: 12px 16px;
      margin: 0px 0px 8px;
      border-radius: 9px;
      font-size: 12px;
      background-color: var(--newLightGrey);
      justify-content: space-between;
      &:hover {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
      }
      .mat-checkbox-wrapper {
        align-items: baseline;
        margin-right: -5px;
      }
    }
  }
}

@media screen and (max-width: 1290px) {
  .livechat-greeting-edit {
    .form-chatboxPreview-wrapper {
      flex-direction: column;
      > .chatbox-preview-v2 {
        padding-left: 25px;
      }
      .ant-form {
        &.mat-form {
          max-width: 100%;
          overflow: visible;
        }
      }
    }
  }
}

@media screen and (max-width: 1190px) {
  .livechat-greeting-edit {
    .form-chatboxPreview-wrapper {
      flex-direction: column;
      .ant-form {
        &.mat-form {
          padding-bottom: 50px;
        }
      }
      > .chatbox-preview-light-theme-v2 {
        padding-bottom: 100px;
        margin: 0 auto;
      }
      > .chatbox-preview-v2 {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 0;
        padding-top: 0;
        margin-bottom: 60px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .livechat-greeting-edit {
    .form-chatboxPreview-wrapper {
      .ant-form {
        &.mat-form {
          max-width: none;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .livechat-greeting-edit {
    .time-on-page-wrapper {
      .row-div-container {
        > * {
          margin-right: 10px;
        }
      }
    }
  }

  .livechat-greeting-item-wrapper {
    .greeting-title {
      font-size: 14px;
    }
  }
}
