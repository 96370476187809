.booking-edit {
  width: 100%;
  h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .booking-edit-wrapper {
    display: flex;
    justify-content: space-between;

    .picker-with-label {
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        padding-left: 10px;

        .row-actions {
          padding-right: 10px;
        }
      }

      .picker-label {
        height: auto;
        color: var(--secondaryTextColor);
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        padding-bottom: 8px;
      }

      .react-datepicker-wrapper {
        padding-top: 10px;
        .react-datepicker__input-container {
          width: 100%;
        }
      }

      .row-actions {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        padding-right: 20px;

        .date-text {
          font-size: 14px;
          color: var(--purple);
          font-weight: 500;
        }
      }
    }
  }
  .mat-select-wrapper {
    .select-label {
      color: var(--secondaryTextColor);
      font-weight: 500;
      padding: 0;
      font-size: 12px;
      line-height: normal;
    }

    .mat-select-sub-wrapper {
      border-style: none;
      border-bottom: 1px solid var(--borderLineColor);
      margin-bottom: 13px;
      padding-bottom: 3px;
      height: auto;

      .ant-select-selector {
        padding: 0 !important;

        .ant-select-selection-item {
          font-weight: 500;
        }
      }

      .ant-select-arrow {
        right: 8px;
        color: var(--secondaryTextColor);
      }
    }
  }
  .btns-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;

    .mat-btn {
      height: 36px;
      width: 127px;

      .anticon-loading {
        display: flex;
        color: var(--mainColor);
      }

      & + .mat-btn {
        margin-left: 10px;
      }
    }

    .mat-btn-white {
      span {
        color: var(--mainColor);
      }
    }
  }
}

.time-picker-block-wrapper {
  display: flex;
  justify-content: space-between;
  .with-select {
    display: flex;
    align-items: center;
  }
  .time-picker-block {
    display: flex;
    align-items: center;
    border: 1px solid var(--borderLineColor);
    border-radius: 8px;
    .point {
      display: flex;
      padding: 0 5px;
    }
    input {
      border: 2px solid transparent;
      border-radius: 8px;
      height: 42px;
      padding: 5px;
      width: 39px;
      text-align: center;
      font-size: 14px;
      &:focus {
        border: 2px solid var(--mainColor);
        font-weight: bold;
      }
    }
  }
  .mat-select-wrapper {
    .mat-select-sub-wrapper {
      height: 44px;
      margin-left: 12px;
      border-radius: 8px;
      border-style: none;
      width: 76px;
      margin-bottom: 0;
      padding-bottom: 0;
      .mat-select {
        &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid transparent !important;
          border-radius: 8px;
          border-style: none !important;
          background-color: var(--mainColorLighter);
          .ant-select-selection-item {
            line-height: 44px;
            color: var(--mainColor);
            padding-right: 8px;
          }
        }
        .ant-select-arrow {
          top: 50%;
          color: var(--mainColor);
        }
      }
    }
  }
  .mat-btn {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
    }
  }
}

.message-tab-pane-wrapper {
  .booking-edit {
    .booking-edit-wrapper {
      flex-direction: column;
      .picker-with-label {
        margin-bottom: 10px;
        .row-actions {
          padding: 10px 9px 10px 0;
        }
        &:nth-child(2) {
          padding-left: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
